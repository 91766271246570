import { Box } from "@mui/material";
import { ReactNode, forwardRef } from "react";
import { Helmet } from "react-helmet-async";

interface PageProps {
    title: string;
    children: ReactNode;
    meta?: ReactNode;
}

const Page = forwardRef<any, PageProps>(({ children, title = "", meta, ...other }, ref) => (
    <>
        <Helmet>
            <title>{`${title} | Elithair`}</title>
            {meta}
        </Helmet>

        <Box ref={ref} sx={{ height: "100%" }} {...other}>
            {children}
        </Box>
    </>
));

export default Page;
