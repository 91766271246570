import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogTitle, Grid, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FormProvider, RHFTextField } from "components/hook-form";
// import { useUploadFile } from "hooks/useFileUploader";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { readClipboard } from "utils/readClipboard";
import * as Yup from "yup";

import { useGetSimulationBulkMutation, useSimulateBulkMutation } from "../helpers/simulations.queries";

type PromotionFormDialogProps = {
    open: boolean;
    onClose: () => void;
};

export const CreateSimulationsDialog = ({ open, onClose }: PromotionFormDialogProps) => {
    // const { mutateAsync: uploadFile } = useUploadFile();
    const { mutateAsync: simulateBulk } = useSimulateBulkMutation();
    const { mutateAsync: getSimulationStatusBulk } = useGetSimulationBulkMutation();

    const Schema = Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                id: Yup.number().required("ID is required"),
                patientId: Yup.string().required("Patient ID is required"),
                original: Yup.string().required("original is required"),
            })
        ),
    });

    const defaultValues: {
        items: {
            id: number;
            patientId: string;
            original: string;
        }[];
    } = {
        items: [],
    };

    const methods = useForm({
        resolver: yupResolver(Schema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting, errors },
        control,
        reset,
        setValue,
        getValues,
        watch,
    } = methods;

    useEffect(() => {
        if (errors.items) {
            errors.items.forEach((error, index) => {
                if (error.patientId) {
                    control.setError(`items.${index}.patientId`, {
                        type: "manual",
                    });
                }
                if (error.original) {
                    control.setError(`items.${index}.original`, {
                        type: "manual",
                    });
                }
            });
        }
    }, [errors, control]);

    const onSubmit = async (values) => {
        try {
            const res = await simulateBulk(values.items);

            const ids = res.map((item) => item._id);

            const result = await getSimulationStatusBulk({
                ids,
                callback: (data: Record<string, { status: string }>) => {
                    Object.entries(data).forEach(([key, value]) => {
                        console.log(key, value);
                        toast.success(`Simulation ${key} is ${value.status}`);
                    });
                },
            });

            console.log(result);
            
            reset(defaultValues);

            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    // const [uploadingFile, setUploadingFile] = useState<{ [lang: string]: boolean }>();

    // const handleFileUpload = async (lang: ILanguage, file: File) => {
    //     const reader = new FileReader();

    //     setUploadingFile({ ...uploadingFile, [lang.code]: true });
    //     reader.onload = () => {
    //         if (typeof reader.result === "string") {
    //             const date = new Date();
    //             const year = date.getFullYear();
    //             const month = date.getMonth() + 1;
    //             const day = date.getDate();

    //             const hash = Math.random().toString(36).substring(7);

    //             const base64String = reader.result as string;
    //             uploadFile({
    //                 bucketName: "elitai",
    //                 fileKey: `test/promotions/${lang.code}/${year}-${month}-${day}/${hash}_${file.name}`,
    //                 contentEncoding: "base64",
    //                 contentType: file.type,
    //                 content: base64String,
    //             })
    //                 .then((response) => {
    //                     const url = response.data.Location;
    //                 })
    //                 .finally(() => {
    //                     setUploadingFile((files) => {
    //                         delete files[lang.code];
    //                         return files;
    //                     });
    //                 });
    //         } else {
    //             throw new Error("Invalid file type");
    //         }
    //     };
    //     reader.readAsDataURL(file);
    // };

    // Data grid props
    const columns = [
        {
            field: "patientId",
            headerName: "Patient ID",
            flex: 1,
            editable: true,
        },
        {
            field: "original",
            headerName: "original",
            flex: 1,
            editable: true,
        },
        {
            field: "preview",
            headerName: "Preview",
            renderCell: (params) => {
                if (params.row.original.startsWith("http")) {
                    return (
                        <img
                            src={params.row.original}
                            alt={params.row.original}
                            style={{ width: 100, height: 100, objectFit: "scale-down" }}
                        />
                    );
                }
                return <></>;
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            renderCell: (params) => (
                <Stack direction="row" spacing={1} alignContent={"end"}>
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={() => {
                            const items = getValues("items");
                            setValue(
                                "items",
                                items.filter((item) => item.id !== params.row.id)
                            );
                        }}
                    >
                        Delete
                    </Button>
                </Stack>
            ),
        },
    ];

    const handleImportFromClipboard = async () => {
        try {
            const clipboard = await readClipboard();
            const rows = clipboard.split("\n").map((row) => row.split("\t"));

            const filteredCols = rows.filter((row) => row[0].length > 0 && row[1].length > 0);

            const items = filteredCols.map((row, index) => ({
                id: index + 1,
                patientId: row[0],
                original: row[1],
            }));

            setValue("items", items);
        } catch (error) {
            console.error(error);
            toast.error("Failed to import from clipboard");
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>Create simulations</DialogTitle>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {watch("items")?.length === 0 ? (
                    <Box p={4}>
                        <Button onClick={handleImportFromClipboard}>Import CSV from Clipboard</Button>
                    </Box>
                ) : (
                    <Box p={4}>
                        <Stack direction="row" spacing={2} alignContent={"end"}>
                            <Button
                                onClick={() => {
                                    reset(defaultValues);
                                }}
                            >
                                Reset
                            </Button>
                        </Stack>
                        <DataGrid columns={columns} rows={watch("items")} disableRowSelectionOnClick rowHeight={75} />
                    </Box>
                )}

                <Box display={"flex"} justifyContent={"space-between"} p={3} gap={1}>
                    <Box display={"flex"} gap={1}>
                        <Button color="inherit" variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                    </Box>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Save
                    </LoadingButton>
                </Box>
            </FormProvider>
        </Dialog>
    );
};
