import { useLocalStorage } from "@elithair/elit-tools";
import axios from "axios";
import endpoints from "endpoints";
import { useLocation, useNavigate } from "react-router";

const useAuth = () => {
    const [session, setSession] = useLocalStorage("session", null);
    const [user, setUser] = useLocalStorage("user", null);
    const { state } = useLocation();
    const navigate = useNavigate();
    const isAuthenticated = !!session;

    const login = async (username: string, password: string) => {
        const { status, data } = await axios.post(endpoints.login, { username, password });
        if (status === 1 && !!data) {
            const { user, accessToken, refreshToken, deviceId } = data;
            console.log({ user });
            setSession({ accessToken, refreshToken, deviceId });
            setUser(user);
            // @ts-ignore
            navigate(state?.from || "/");
        }
    };

    const logout = () => {
        setSession(null);
        navigate("/auth/login");
    };

    return { isAuthenticated, session, user, login, logout };
};

export default useAuth;
