// MUI Localization
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";

const domNode = document.getElementById("root");
const root = ReactDOM.createRoot(domNode);

root.render(
    <HelmetProvider>
        <SettingsProvider>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <CollapseDrawerProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </CollapseDrawerProvider>
            </LocalizationProvider>
        </SettingsProvider>
    </HelmetProvider>
);
