import { Box } from "@mui/material";
// @mui
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
    const theme = useTheme();
    // const PRIMARY_LIGHT = theme.palette.primary.light;
    const PRIMARY_MAIN = theme.palette.primary.main;
    // const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
        <Box sx={{ width: "auto", height: 40, ...sx }}>
            <svg width="{auto}" height="{100%}" viewBox="0 0 102 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_15576_18324)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.98815 8.03636L7.83795 8.05454L7.81315 13.3745C7.80607 14.7964 6.6692 15.9527 5.2844 15.9491L3.95274 15.9418C1.82774 15.9309 0.110029 14.1527 0.120654 11.9709C0.131279 9.78909 1.86315 8.02545 3.98815 8.03636Z"
                        fill="#003870"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.6806 8.04727C15.272 7.44 15.5058 6.63273 15.5058 5.75636V4.09454C15.5058 1.91272 13.781 0.141815 11.656 0.141815C9.531 0.141815 7.80621 1.91272 7.80621 4.09454V13.3673C7.80621 14.7891 6.67288 15.9527 5.28809 15.9527H5.57496C6.5135 15.9527 7.32454 15.6073 7.99038 14.9236L14.677 8.04727H14.6806Z"
                        fill="#5799F1"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3591 15.9527H15.5093V10.4036C15.5093 9.10909 16.5434 8.04727 17.8043 8.04727H19.3591C21.4841 8.04727 23.2089 9.81818 23.2089 12C23.2089 14.1818 21.4841 15.9527 19.3591 15.9527Z"
                        fill="#5799F1"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.63478 15.9527C8.04332 16.56 7.80957 17.3636 7.80957 18.24V19.9055C7.80957 22.0873 9.53436 23.8582 11.6594 23.8582C13.7844 23.8582 15.5092 22.0873 15.5092 19.9055V10.6327C15.5092 9.21091 16.6425 8.04727 18.0273 8.04727H17.7404C16.7983 8.04727 15.9873 8.39273 15.3214 9.07636L8.63478 15.9527Z"
                        fill="#003870"
                    />
                    <path
                        d="M11.6203 21.76C12.3265 21.76 12.8989 21.1723 12.8989 20.4473C12.8989 19.7223 12.3265 19.1346 11.6203 19.1346C10.9142 19.1346 10.3418 19.7223 10.3418 20.4473C10.3418 21.1723 10.9142 21.76 11.6203 21.76Z"
                        fill="white"
                    />
                    <path
                        d="M19.862 13.3127C20.5682 13.3127 21.1406 12.725 21.1406 12C21.1406 11.275 20.5682 10.6873 19.862 10.6873C19.1559 10.6873 18.5835 11.275 18.5835 12C18.5835 12.725 19.1559 13.3127 19.862 13.3127Z"
                        fill="white"
                    />
                    <path
                        d="M40.7081 6.69454C40.9738 6.69454 41.1898 6.61818 41.3527 6.46181C41.5156 6.30545 41.5971 6.10545 41.5971 5.85454C41.5971 5.60363 41.5156 5.38181 41.3527 5.21818C41.1898 5.05818 40.9738 4.97454 40.7081 4.97454H32.8315C32.5765 4.97454 32.3604 5.06181 32.1869 5.23272C32.0133 5.40363 31.9248 5.62909 31.9248 5.90181V18.0145C31.9248 18.2764 32.0133 18.4982 32.1869 18.6764C32.3604 18.8545 32.5765 18.9454 32.8315 18.9454H40.7081C40.9738 18.9454 41.1898 18.8655 41.3527 18.7055C41.5156 18.5455 41.5971 18.3309 41.5971 18.0691C41.5971 17.8073 41.5156 17.6145 41.3527 17.4618C41.1898 17.3054 40.9738 17.2291 40.7081 17.2291H33.7381V12.7564H38.739C39.0046 12.7564 39.2206 12.6764 39.3836 12.5127C39.5465 12.3527 39.6279 12.1454 39.6279 11.8945C39.6279 11.6436 39.5465 11.44 39.3836 11.2764C39.2206 11.1164 39.0046 11.0327 38.739 11.0327H33.7381V6.68363H40.7081V6.69454Z"
                        fill="#003366"
                    />
                    <path
                        d="M47.3453 17.1636H46.9096C46.6546 17.1636 46.4457 17.0218 46.2828 16.7345C46.1199 16.4473 46.0384 16.0836 46.0384 15.6436V5.87273C46.0384 5.61091 45.9569 5.39636 45.794 5.22909C45.6311 5.06182 45.4221 4.97818 45.1671 4.97818C44.9121 4.97818 44.7032 5.06182 44.5403 5.22909C44.3774 5.39636 44.2959 5.61091 44.2959 5.87273V15.6436C44.2959 16.2873 44.4057 16.8582 44.6253 17.3527C44.8449 17.8473 45.153 18.24 45.5496 18.5236C45.9428 18.8109 46.3961 18.9527 46.9096 18.9527H46.9451C47.2957 18.9527 47.579 18.8691 47.7986 18.7018C48.0182 18.5345 48.128 18.32 48.128 18.0582C48.128 17.7964 48.0536 17.5818 47.9084 17.4145C47.7632 17.2473 47.5755 17.1636 47.3417 17.1636H47.3453Z"
                        fill="#003366"
                    />
                    <path
                        d="M51.634 5.21091C51.3187 5.21091 51.0496 5.32727 50.8229 5.56C50.5962 5.79272 50.4829 6.06909 50.4829 6.39273C50.4829 6.71636 50.5962 6.99273 50.8229 7.22545C51.0496 7.45818 51.3187 7.57454 51.634 7.57454C51.9492 7.57454 52.2183 7.45818 52.445 7.22545C52.6717 6.99273 52.785 6.71636 52.785 6.39273C52.785 6.06909 52.6717 5.79272 52.445 5.56C52.2183 5.32727 51.9492 5.21091 51.634 5.21091Z"
                        fill="#003366"
                    />
                    <path
                        d="M51.6341 9.16363C51.3791 9.16363 51.1666 9.24726 50.9966 9.41454C50.8266 9.58181 50.7451 9.80363 50.7451 10.0764V18.04C50.7451 18.3018 50.8301 18.52 50.9966 18.6945C51.1666 18.8691 51.3755 18.9527 51.6341 18.9527C51.8926 18.9527 52.1157 18.8654 52.2787 18.6945C52.4416 18.52 52.523 18.3054 52.523 18.04V10.0764C52.523 9.80363 52.4416 9.58181 52.2787 9.41454C52.1157 9.24726 51.8997 9.16363 51.6341 9.16363Z"
                        fill="#003366"
                    />
                    <path
                        d="M60.5911 17.1636H59.9996C59.6277 17.1636 59.3125 17.0036 59.0575 16.68C58.8025 16.3564 58.675 15.96 58.675 15.48V11.0618H60.1554C60.3998 11.0618 60.5981 10.9891 60.7469 10.8473C60.8992 10.7054 60.9736 10.5236 60.9736 10.3091C60.9736 10.0691 60.8992 9.87999 60.7469 9.73818C60.5946 9.59636 60.3963 9.52363 60.1554 9.52363H58.675V6.94545C58.675 6.68363 58.5935 6.46909 58.4306 6.30181C58.2677 6.13454 58.0588 6.0509 57.8038 6.0509C57.5488 6.0509 57.3398 6.13454 57.1769 6.30181C57.014 6.46909 56.9325 6.68363 56.9325 6.94545V9.52363H56.1321C55.8877 9.52363 55.6894 9.59636 55.5406 9.73818C55.3883 9.87999 55.314 10.0727 55.314 10.3091C55.314 10.5236 55.3883 10.7018 55.5406 10.8473C55.6929 10.9891 55.8877 11.0618 56.1321 11.0618H56.9325V15.48C56.9325 16.1345 57.0671 16.7273 57.3327 17.2509C57.5983 17.7745 57.9667 18.1891 58.4306 18.4945C58.8946 18.8 59.4188 18.9491 59.9996 18.9491H60.3148C60.6158 18.9491 60.8638 18.8654 61.055 18.6982C61.2463 18.5309 61.3419 18.3164 61.3419 18.0545C61.3419 17.7927 61.271 17.5782 61.1258 17.4109C60.9806 17.2436 60.8035 17.16 60.5946 17.16L60.5911 17.1636Z"
                        fill="#003366"
                    />
                    <path
                        d="M71.1064 9.60364C70.4725 9.25091 69.7571 9.07636 68.9531 9.07636C68.1491 9.07636 67.4939 9.25091 66.8812 9.59636C66.5448 9.78545 66.2508 10.0145 65.9923 10.2764V5.89091C65.9923 5.61818 65.9108 5.39636 65.7479 5.22909C65.585 5.06182 65.3689 4.97818 65.1033 4.97818C64.8377 4.97818 64.6358 5.06182 64.4658 5.22909C64.2958 5.39636 64.2144 5.61818 64.2144 5.89091V18.0582C64.2144 18.32 64.2994 18.5382 64.4658 18.7127C64.6358 18.8873 64.8448 18.9709 65.1033 18.9709C65.3619 18.9709 65.585 18.8836 65.7479 18.7127C65.9108 18.5382 65.9923 18.3236 65.9923 18.0582V13.0109C65.9923 12.5709 66.1091 12.1782 66.3394 11.84C66.5731 11.5018 66.8848 11.2291 67.2814 11.0255C67.6781 10.8218 68.1173 10.72 68.606 10.72C69.1408 10.72 69.6154 10.8255 70.0262 11.0327C70.4371 11.24 70.7664 11.56 71.0108 11.9891C71.2552 12.4182 71.3756 12.9745 71.3756 13.6545V18.0545C71.3756 18.3164 71.4606 18.5345 71.6271 18.7091C71.7971 18.8836 72.006 18.9673 72.2646 18.9673C72.5231 18.9673 72.7321 18.88 72.9021 18.7091C73.0721 18.5345 73.1535 18.32 73.1535 18.0545V13.6545C73.1535 12.6655 72.9694 11.8291 72.6046 11.1491C72.2398 10.4691 71.7369 9.95273 71.1064 9.6V9.60364Z"
                        fill="#003366"
                    />
                    <path
                        d="M83.5765 9.74545C82.8575 9.30909 82.0429 9.0909 81.1362 9.0909C80.2296 9.0909 79.415 9.30909 78.6889 9.74545C77.9629 10.1818 77.3892 10.7745 76.9642 11.5273C76.5392 12.28 76.3267 13.1273 76.3267 14.0691C76.3267 15.0109 76.5214 15.8545 76.911 16.6C77.3006 17.3454 77.8319 17.9345 78.5048 18.3709C79.1777 18.8073 79.9392 19.0254 80.7892 19.0254C81.6392 19.0254 82.3971 18.8073 83.0735 18.3709C83.4914 18.1018 83.8456 17.7673 84.1537 17.3782V18.0582C84.1537 18.32 84.2387 18.5382 84.4052 18.7127C84.5752 18.8873 84.7842 18.9709 85.0427 18.9709C85.3012 18.9709 85.5102 18.8836 85.6802 18.7127C85.8502 18.5418 85.9317 18.3236 85.9317 18.0582V14.0691C85.9317 13.1273 85.7192 12.28 85.2942 11.5273C84.8692 10.7745 84.299 10.1818 83.5765 9.74545ZM83.8208 15.7745C83.5517 16.28 83.1869 16.68 82.7229 16.9745C82.259 17.2654 81.7312 17.4145 81.1362 17.4145C80.5412 17.4145 80.0312 17.2691 79.5602 16.9745C79.0892 16.6836 78.7173 16.2836 78.4446 15.7745C78.1719 15.2691 78.0337 14.6982 78.0337 14.0654C78.0337 13.4327 78.1719 12.8473 78.4446 12.3382C78.7173 11.8327 79.0892 11.4327 79.5602 11.1382C80.0312 10.8473 80.5554 10.6982 81.1362 10.6982C81.7171 10.6982 82.2554 10.8436 82.7229 11.1382C83.1869 11.4291 83.5517 11.8291 83.8208 12.3382C84.0865 12.8436 84.221 13.4218 84.221 14.0654C84.221 14.7091 84.0865 15.2654 83.8208 15.7745Z"
                        fill="#003366"
                    />
                    <path
                        d="M90.3376 5.21091C90.0224 5.21091 89.7532 5.32727 89.5265 5.56C89.2999 5.79272 89.1865 6.06909 89.1865 6.39273C89.1865 6.71636 89.2999 6.99273 89.5265 7.22545C89.7532 7.45818 90.0224 7.57454 90.3376 7.57454C90.6528 7.57454 90.9219 7.45818 91.1486 7.22545C91.3753 6.99273 91.4886 6.71636 91.4886 6.39273C91.4886 6.06909 91.3753 5.79272 91.1486 5.56C90.9219 5.32727 90.6528 5.21091 90.3376 5.21091Z"
                        fill="#003366"
                    />
                    <path
                        d="M90.3377 9.16363C90.0827 9.16363 89.8702 9.24726 89.7002 9.41454C89.5302 9.58181 89.4487 9.80363 89.4487 10.0764V18.04C89.4487 18.3018 89.5337 18.52 89.7002 18.6945C89.8702 18.8691 90.0792 18.9527 90.3377 18.9527C90.5962 18.9527 90.8194 18.8654 90.9823 18.6945C91.1452 18.52 91.2266 18.3054 91.2266 18.04V10.0764C91.2266 9.80363 91.1452 9.58181 90.9823 9.41454C90.8194 9.24726 90.6033 9.16363 90.3377 9.16363Z"
                        fill="#003366"
                    />
                    <path
                        d="M101.462 9.40727C101.119 9.17455 100.598 9.05818 99.9038 9.05818C99.2096 9.05818 98.5686 9.22545 97.9877 9.56C97.5946 9.78545 97.2617 10.0691 96.9783 10.4V10.0582C96.9783 9.76 96.904 9.53818 96.7588 9.38909C96.6136 9.24 96.394 9.16363 96.1071 9.16363C95.8202 9.16363 95.6148 9.24 95.4625 9.39636C95.3102 9.55272 95.2358 9.77091 95.2358 10.0582V18.0545C95.2358 18.3527 95.3102 18.5782 95.4625 18.7273C95.6148 18.8764 95.8273 18.9491 96.1071 18.9491C96.3869 18.9491 96.6136 18.8727 96.7588 18.7164C96.904 18.56 96.9783 18.3418 96.9783 18.0545V12.8291C96.9783 12.28 97.1483 11.8255 97.4919 11.4691C97.8354 11.1091 98.3029 10.8655 98.8944 10.7345C99.4858 10.6036 100.155 10.6145 100.899 10.7709C101.051 10.8073 101.189 10.8145 101.317 10.7891C101.444 10.7673 101.558 10.7091 101.657 10.6182C101.756 10.5273 101.823 10.4 101.859 10.2327C101.94 9.90909 101.809 9.63273 101.466 9.4L101.462 9.40727Z"
                        fill="#003366"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_15576_18324">
                        <rect width="102" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
