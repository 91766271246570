import { Avatar, Box, Link, Typography } from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
import useAuth from "hooks/useAuth";
import PropTypes from "prop-types";
import createAvatar from "utils/createAvatar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
    isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
    const { user } = useAuth();
    const fullName = `${user?.firstName} ${user?.lastName}`;
    return (
        <Link underline="none" color="inherit">
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: "transparent",
                    }),
                }}
            >
                <Avatar
                    src={`${user?.avatarPath}/${user?.avatar}`}
                    alt={fullName}
                    color={user?.avatar ? "default" : createAvatar(fullName).color}
                >
                    {createAvatar(fullName).name}
                </Avatar>

                <Box
                    sx={{
                        ml: 2,
                        transition: (theme) =>
                            theme.transitions.create("width", {
                                duration: theme.transitions.duration.shorter,
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0,
                        }),
                    }}
                >
                    <Typography variant="subtitle2" noWrap>
                        {fullName}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
                        {user?.role?.name}
                    </Typography>
                </Box>
            </RootStyle>
        </Link>
    );
}
