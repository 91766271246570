import {
    Box,
    Button,
    Card,
    Container,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Page from "components/Page";
import { DateTime } from "luxon";
import { useState } from "react";
import { TbHourglass } from "react-icons/tb";

import { useListSimulations } from "./helpers/simulations.queries";
import { CreateSimulationsDialog } from "./layouts/CreateSimulationsDialog";

export default function ElitAiSimulationsPage() {
    // --- Filtering ---
    const [from, setFrom] = useState<DateTime>(DateTime.now().startOf("day"));
    const [to, setTo] = useState<DateTime>(DateTime.now().startOf("day").plus({ days: 1 }));

    const handleSetDay = (day: DateTime) => {
        setFrom(day.startOf("day"));
        setTo(day.startOf("day").plus({ days: 1 }));
    };

    // --- List ---
    const { data: simulations = [], isLoading: loadingPromotions } = useListSimulations(from, to);

    // --- List Actions ---

    // --- Form actions ---
    const [createSimulationDialogOpen, setCreateSimulationDialogOpen] = useState(false);
    const handleOpenCreateSimulationDialog = () => setCreateSimulationDialogOpen(true);
    const handleCloseCreateSimulationDialog = () => setCreateSimulationDialogOpen(false);

    return (
        <Page title="Simulations">
            <Container sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h3" component="h1" paragraph>
                        Simulations
                    </Typography>

                    <Stack direction={"row"} spacing={2}>
                        <Button variant="outlined" onClick={handleOpenCreateSimulationDialog}>
                            Create simulations
                        </Button>
                    </Stack>
                </Stack>

                <Card sx={{ mb: 4, display: "flex", flexDirection: "column" }}>
                    <Stack spacing={2} direction={{ xs: "column", md: "row" }} sx={{ py: 2.5, px: 3 }}>
                        <DatePicker
                            label="Controlled picker"
                            value={from}
                            onChange={(newValue) => handleSetDay(newValue)}
                            slots={{
                                textField: (params) => <TextField {...params} />,
                            }}
                        />
                    </Stack>

                    <Box sx={{ minWidth: 800, position: "relative", overflow: "scroll" }}>
                        <TableContainer>
                            <Table size={"medium"}>
                                <TableBody>
                                    {loadingPromotions ? (
                                        <TableRow>
                                            <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                                                Loading...
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        simulations?.map((simulation) => (
                                            <TableRow key={simulation._id}>
                                                <TableCell width={70} sx={{ textAlign: "left" }}>
                                                    <IconButton
                                                        onClick={() => {
                                                            window.open(simulation.original, "_blank");
                                                        }}
                                                    >
                                                        <img
                                                            src={simulation.original}
                                                            alt={`simulation-${simulation._id}`}
                                                            style={{
                                                                minWidth: 50,
                                                                minHeight: 50,
                                                                width: 50,
                                                                height: 50,
                                                                borderRadius: "50%",
                                                                objectFit: "cover",
                                                                boxShadow: "0 0 0 3px #2569A8",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell width={150} sx={{ textAlign: "left" }}>
                                                    <Stack>
                                                        <Typography variant="subtitle1" noWrap>
                                                            {simulation.patientId}
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize={10} noWrap>
                                                            {simulation.jobID}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell width={150} sx={{ textAlign: "center" }}>
                                                    <Stack direction={"row"} spacing={1}>
                                                        {simulation?.images?.map((image, index) => (
                                                            <IconButton
                                                                size="small"
                                                                key={index}
                                                                onClick={() => {
                                                                    window.open(image, "_blank");
                                                                }}
                                                            >
                                                                <img
                                                                    src={image}
                                                                    alt={`simulation-${simulation._id}-${index}`}
                                                                    style={{
                                                                        minWidth: 50,
                                                                        minHeight: 50,
                                                                        width: 50,
                                                                        height: 50,
                                                                        borderRadius: "50%",
                                                                        objectFit: "scale-down",
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        ))}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell width={150} sx={{ textAlign: "center" }}>
                                                    <Stack>
                                                        <Typography variant="subtitle1" noWrap>
                                                            {simulation.status}
                                                        </Typography>
                                                        <Typography fontFamily={"monospace"} fontSize={10} noWrap>
                                                            {`(${simulation.generationEnd
                                                                .diff(simulation.generationStart)
                                                                .as("seconds")
                                                                .toFixed(2)} S)`}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell width={150} sx={{ textAlign: "left" }}>
                                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                        <TbHourglass size={20} />
                                                        <Stack>
                                                            <Typography noWrap fontSize={12}>
                                                                {simulation.generationStart.toLocaleString(
                                                                    DateTime.TIME_24_WITH_SECONDS
                                                                )}
                                                            </Typography>
                                                            <Typography noWrap fontSize={12}>
                                                                {simulation.generationEnd.toLocaleString(
                                                                    DateTime.TIME_24_WITH_SECONDS
                                                                )}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Card>
            </Container>

            <CreateSimulationsDialog open={createSimulationDialogOpen} onClose={handleCloseCreateSimulationDialog} />
        </Page>
    );
}
